import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Style = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: calc(var(--spacing-medium) * 2.5) calc(var(--spacing-medium) * 3);

  @media screen and (max-width: 834px) {
    grid-template-columns: 1fr;
    gap: var(--spacing-medium);
  }

  & > * {
    &.group > * {
      height: auto !important; 

      &:first-child {
        margin-bottom: calc(var(--spacing-medium) * 2.5);
      }
    }

    @media not screen and (max-width: 834px) {
      &:nth-child(1),
      &:nth-child(3) {
        grid-column: 1;
      }

      &:nth-child(2),
      &:nth-child(4),
      &:nth-child(5) {
        grid-column: 2;
      }
    }
  }
`;

const SymptomsColumns = ({ children }) => (
  <Style>
    { children }
  </Style>
);

SymptomsColumns.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export default SymptomsColumns;
